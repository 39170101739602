import { z } from "zod";

import "./uploads";

import { UploadWithPhotosShape } from "./upload-with-photos";

export const PatientLinkShape = z.object({
  id: z.string().nullable().optional(),
  createdAt: z.coerce.date(),
  token: z.string().nullable().optional(),
  validUntil: z.coerce.date(),
});

export const UploadsShapeArray = z.array(UploadWithPhotosShape);

export const PatientLinksShape = z.array(PatientLinkShape);

const PatientEmailValidation = z.object({
  id: z.string(),
  createdAt: z.coerce.date().nullable().optional(),
  email: z.string(),
});

export const patientShapeBase = z.object({
  name: z.string().min(2, {
    message: "Patient name must be at least 2 characters long.",
  }),
  email: z
    .string()
    .email({
      message: "Patient email must be valid.",
    })
    .optional()
    .nullable(),
  phone: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  address2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional(),
  externalId1: z.string().nullable().optional(),
  externalId2: z.string().nullable().optional(),
  clientId: z.string().nullable().optional(),
});

export const patientShapeEdit = patientShapeBase.extend({
  id: z.string(),
});

export const patientShapeCreate = patientShapeBase.extend({});

export const patientReadShape = patientShapeCreate.extend({
  uploads: UploadsShapeArray.optional(),
  patientLinks: PatientLinksShape.optional().nullable(),
  emailValidation: PatientEmailValidation.optional().nullable(),
  id: z.string(),
});

export type PatientReadShape = z.infer<typeof patientReadShape>;
