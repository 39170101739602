"use client";

import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { LoggedUserData, sessionLoginResult } from "@acme/types";
import { logger } from "@acme/utils";

import { useStore } from "~/hooks/store";
import { fbAuth } from "~/lib/auth/firebase-app";

export const AuthComponent = () => {
  const setLoggedUser = useStore((s) => s.setLoggedUser);
  useEffect(() => {
    getLoggedUser()
      .then((r) => {
        setLoggedUser(r.user);
      })
      .catch((e) => {
        console.error(e);
      });

    const unsubscribe = onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        const u = {
          ...user,
          displayName: user.displayName || "",
          email: user.email || "",
          photoURL: user.photoURL || "",
        };

        setLoggedUser(u);
      }
    });

    return () => unsubscribe();
  }, []);

  return null;
};

const getLoggedUser = async () => {
  const url = "/api/session-login";
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    logger.warn("getLoggedUser", {
      status: res.status,
      statusText: res.statusText,
    });
    return { user: null, status: "error" };
  }

  return sessionLoginResult.parse(await res.json());
};

export const logOut = () => {
  logger.debug("userSignOut");

  useStore.getState().setLoggedUser(null);
  return fbAuth.signOut();
};

export const logIn = (user: LoggedUserData) => {
  logger.debug("userSignIn", user);
  useStore.getState().setLoggedUser(user);
};
